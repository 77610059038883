import React from "react";
import Link from "next/link";
import styles from "./index.module.scss";
import Button from "../../UI/Button";
import Image from "next/image";

export default function PageNotFound() {
  return (
    <div className={styles.root}>
      <Image
        src="/images/box.svg"
        width="618"
        height="519"
        alt="404"
        loading="lazy"
        className={styles.img}
      />
      <label className={styles.label}>Sorry, We Can't Find That Page</label>
      <p className={styles.text}>The link might be broken or the page may have been removed. Please check the URL or head back to the main page.</p>
      <div className={styles.actions}>
        <Link href="/">
          <Button title="Go to homepage" />
        </Link>
      </div>
    </div>
  );
}
